<template>
    <div>
        <div class="man-title">
            <span>修改密码</span>
        </div>
        <div class="form-box">
            <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
                <el-form-item label="账号" prop="phone">
                    <el-input v-model="form.phone" type="number" placeholder="请输入账号"></el-input>
                </el-form-item>
                <el-form-item label="旧密码" prop="oldPwd">
                    <el-input v-model="form.oldPwd" type="number" placeholder="请输入旧密码"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPwd">
                    <el-input v-model="form.newPwd" type="number" placeholder="请输入新密码"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-button type="primary" @click="onSubmit" size="small">确定</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import md5 from 'md5'
export default {
    data() {
        return {
            form: {},
            rules: {
                phone:[{ required: true, message: '必填项', trigger: 'blur' }],
                oldPwd:[{ required: true, message: '必填项', trigger: 'blur' }],
                newPwd:[{ required: true, message: '必填项', trigger: 'blur' }]
            }
        }
    },
    methods: {
        onSubmit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.$ajax.post('changePassword', {
                        phone: this.form.phone,
                        oldPwd: md5(this.form.oldPwd),
                        newPwd: md5(this.form.newPwd)
                    }).then(res => {
                        if (res.code == 0) {
                            this.form = {}
                            this.$message.success(res.msg)
                        }
                    })
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.form-box {
    width: 400px;
}
/deep/input::-webkit-outer-spin-button,
/deep/input::-webkit-inner-spin-button { -webkit-appearance: none; }
/deep/input[type="number"] { -moz-appearance: textfield; }
</style>
